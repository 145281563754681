<style lang="scss" scoped>
body {
  margin: 0 auto;
}

#app {
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: rgb(241, 239, 241);
  .content {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }
}
</style>

<template>
  <div id="app" @dragstart.prevent @selectstart.prevent>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // 禁止双指缩放触发浏览器缩放
    document.documentElement.addEventListener(
      "touchstart",
      function(event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );
  },
};
</script>
